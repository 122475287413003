'use strict';

function TickerTextArea(config){
  var self = this;
  var placeholder = (config && config.placeholder) || null;
  var value = (config && config.value) || null;
  var autofocus = (config && config.autofocus) || false;

  // Maximum number of lines
  this.rows = (config && config.rows) || 1;
  // Maximum number of columns (used to detect line wraps)
  this.cols = (config && config.cols) || 52; 

  this.onInput = (config && config.onInput) || function(){};
  this.onKeyDown = (config && config.onKeyDown) || function(){};
  this.onKeyUp = (config && config.onKeyUp) || function(){};

  this.lines = [];
  this.textarea = TickerTextArea.createTextArea(value, placeholder, autofocus);
  this.updateLines();

  this.container = TickerTextArea.createContainer();
  this.tickerTape = TickerTextArea.createTickerTape(this.lines);

  this.container.append(this.textarea);
  this.container.append(this.tickerTape);

  // Prevent user input and remove placeholder text.
  this.disable = function(){
    this.textarea.disabled = true;
    this.textarea.placeholder = '';
  };

  // Filter the string, calculate line breaks, and render
  //   the text area text and underlying tapes
  this.renderMessage = function(msg){
    self.textarea.value = TickerTextArea.filterInput(msg);
    try {
      self.updateLines();
      self.updateTickerTape();
    }
    catch(error) {
      document.execCommand('undo', false, null);
    }
  };

  // Add focus
  this.focus = function(){
    self.textarea.focus();
  };

  // Handle user input. Similar to `renderMessage()`, but 
  //   repositions cursor as necessary (e.g. if filtering
  //   caused some letters to be deleted) and calls the 
  //   `onInput` callback.
  function handleInput(){
    var pos = self.textarea.selectionStart;
    self.textarea.value = TickerTextArea.filterInput(self.textarea.value);
    self.textarea.setSelectionRange(pos, pos);

    try {
      self.updateLines();
      self.updateTickerTape();
    }
    catch(error) {
      document.execCommand('undo', false, null);
      return;
    }
    self.onInput();
  }

  this.textarea.addEventListener('input', handleInput);
  this.textarea.addEventListener('keyup', this.onKeyUp);
  this.textarea.addEventListener('keydown', this.onKeyDown);
}

TickerTextArea.prototype.addLine = function(line){
  if (this.lines.length >= this.rows) {
    throw 'Too many lines';
  }
  if (!line.match(/\As.\z/)){
    this.lines.push(line);
  }
};

// Removes tape backings and replaces them with new ones.
TickerTextArea.prototype.updateTickerTape = function(){
  this.tickerTape.remove();
  this.tickerTape = TickerTextArea.createTickerTape(this.lines);
  this.container.append(this.tickerTape);
};

// Calculates line wraps and update internal `lines` state
//   accordingly.
TickerTextArea.prototype.updateLines = function(){
  var text = this.textarea.value || this.textarea.placeholder;
  var words = text.split(' ');
  var buff = '';

  this.lines = [];

  for (var j = 0; j < words.length; j++) {
    var word = words[j];
    if (word.length + buff.length > this.cols){
      this.addLine(buff);
      buff = '';
    }
    buff += ' ' + word;
  }

  this.addLine(buff);
};

// Creates the div wrapper
TickerTextArea.createContainer = function(){
  var c = document.createElement('div');
  return c;
};

// Creates text area
TickerTextArea.createTextArea = function(value, placeholder, autofocus){
  var t = document.createElement('textarea');
  t.autofocus = autofocus;
  if (value){
    t.value = value;
  }
  else if (placeholder) {
    t.placeholder = placeholder;
  }
  return t;
};

// Creates underlying ticker tape(s)
TickerTextArea.createTickerTape = function(lines) {
  var wrapper = document.createElement('div');
  wrapper.className = 'ticker-tape-wrapper';

  for (var i = 0; i < lines.length; i++){
    if (lines[i].match(/^\s*$/)){
      continue;
    }
    var p = document.createElement('P');
    var br = document.createElement('BR');
    p.innerHTML = lines[i] + '=';
    wrapper.append(p);
    wrapper.append(br);
  }

  return wrapper;
};

// Filters out invalid character(s) from user input
TickerTextArea.filterInput = function(s){
  if (s === ''){
    return s;
  }
  s = s.toUpperCase()
    .replace(/[^A-Z0-9 !\-\?\/:\$&\)\(;\.,\'\"#]/g, '')
    .replace(/\s+/g, ' ')
    .replace(/HTTPS[^\s]+/g, '')
    .replace(/[^A-Z0-9!?.\"\']\s+$/, '');
  return s;
};