'use strict';

(function(document, window, undefined){
  var baseName, defaults, libName, noop;
   
  libName = 'MobilePayBtn';
  baseName = 'mobile-pay-btn';

  /**
   * Default MobilePayBtn configurations
   * 
   * @namespace
   **/

  defaults = {
    mode: 'test',
    key: 'pk_live_k40lgujeZX7F0uo6lFeCduLj' ,
    name: 'Wireless Telegrams',
    testKey: 'pk_test_fgBuzZap6LHBDQ3LUVmc5x9A',
    tokenHandler: function(t){ 
      console.log(libName + ': token ' + t + ' received');
    }
  };

  noop = function(){};


  /**
   * 
   * @class
   *  
   * @param {object} config - dictionary of configuration options
   * @param {number} config.amount - purchase amount (¢)
   * @param {string} config.description - purchase item description
   * @param {string} config.mode - can be 'test' or 'live'
   * @param {function} [_config.tokenHandler] - handle successful token 
   * 
   * @prop {object} stripe - Stripe elements instance (see 
   *   stripe.com/docs/stripe-js/elements/payment-request-button)
   * @prop {object} paymentRequest - Stripe payment request instance 
   * @prop {number} amount - purchase amount (in ¢)
   * @prop {string} description - item description
   * @prop {object} _config - configuration
   * @prop {string} [_config.key] - api key in use
   * @prop {string} [_config.mode] - determines api key: 'test' or 'live'
   * @prop {string} [_config.name] - merchant name (IvyInsights, Inc.)
   * @prop {string} [_config.testKey] - test api key
   * @prop {function} [_config.tokenHandler] - handle successful token 
   *                                           response from api 
   * @prop {string} description - purchase item description
   *
   **/
  function MobilePayBtn(config){
    var elem, k, self, v;

    self = this;

    config = config || {};

    // support query string
    if (typeof elem === 'string'){
      elem = document.querySelector(elem);
    }

    // validate price
    if (!config.amount || typeof config.amount !== 'number'){
      console.warn(libName + ': amount must be a number');
    }

    // validate description
    if (!config.description || typeof config.description !== 'string'){
      console.warn(libName + ': description must be a string');
    }

    // set configs
    this._config = {};
    for (k in defaults){
      v = defaults[k];
      if (k in config){
        this._config[k] = config[k];
      } else{
        this._config[k] = v;
      }
    }

    this.amount = config.amount;
    this.description = config.description;

    // set api key
    if (this._config.mode !== 'live'){
      this._config.key = this._config.testKey;
    }

    this.stripe = new Stripe(this._config.key);
    this.paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: this.description,
        amount: this.amount
      },
    });

    this.paymentRequest.on('token', function(ev){
      if (ev && ev.token && ev.token.id) {
        self._config.tokenHandler(ev.token.id);
        ev.complete('success');
      }
    });

  }


  /**
   * Display the payment prompt
   * 
   * @function
   * @return {object} - this MobilePayBtn instance 
   * 
   **/
  MobilePayBtn.prototype.show = function(){
    var elements = this.stripe.elements();
    var prButton = elements.create('paymentRequestButton', {
      paymentRequest: this.paymentRequest,
    });

    // Check the availability of the Payment Request API first.
    this.paymentRequest.canMakePayment().then(function(result) {
      if (result) {
        prButton.mount('#payment-request-button');
      } else {
        document.getElementById('payment-request-button')
          .style.display = 'none';
      }
    });

    return this;
  };

  // export MobilePayBtn
  window.MobilePayBtn = MobilePayBtn;

})(document, window);