'use strict';

(function(window, document, undefined){
  
  var CLASS_NAME; // Class name
  var DEFAULTS; // Default constructor parameters
  var LIBNAME; // Name of this module
  
  var PRICE_CLASS_NAME;
  var WARN;

  CLASS_NAME = 'buy-button';
  DEFAULTS = {
    amount: null,
    href: null,
    onClick: null
  };
  LIBNAME = 'BuyButtonView';

  /**
   * @function 
   * @param {number} amount - button price
   * @return {string} - Class name for button with the given price
   **/
  PRICE_CLASS_NAME = function(amount){
    return CLASS_NAME + '-' + amount;
  };

  /**
   * @function 
   * Print the given message as a console warning
   **/
  WARN = function(msg){
    console.warn('[' + LIBNAME + ']: ' + msg);
  };

  /**
   * @class 
   * Buy button
   *
   * @param {object} params - parameters
   * @param {number} params.amount - item price (in ¢)
   * 
   * @prop {number} amount - item price (in ¢)
   **/
  function BuyButtonView(params){
    params = params || {};

    this.amount = params.amount === 0 ? 0 : 
      (params.amount || DEFAULTS.amount);
    this.onClick = params.onClick || DEFAULTS.onClick;
    this.href = params.href || DEFAULTS.href;

    if (typeof this.amount !== 'number'){
      WARN('`amount` must be a string, but got: ' + this.amount);
    }
  }

  /**
   * @function
   * Render this buy button view
   * @return {object} - HTML 'A' element
   **/
  BuyButtonView.prototype.render = function(){
    var a;

    a = document.createElement('A');

    a.classList.add(CLASS_NAME);
    a.classList.add(PRICE_CLASS_NAME(this.amount));
    
    if (this.href){
      a.href = this.href;
    }

    if (this.onClick){
      a.addEventListener('click', this.onClick);
    }

    return a;
  };

  //Export module
  window.BuyButtonView = BuyButtonView;


})(window, document);