'use strict';

(function(document, window, undefined){
  var baseName, defaults, libName, noop;
   
  libName = 'PaymentPrompt';
  baseName = 'payment-prompt';

  /**
   * Default PaymentPrompt configurations
   * 
   * @namespace
   **/

  defaults = {
    mode: 'test',
    key: 'pk_live_k40lgujeZX7F0uo6lFeCduLj' ,
    name: 'Wireless Telegrams',
    testKey: 'pk_test_fgBuzZap6LHBDQ3LUVmc5x9A',
    tokenHandler: function(t){ 
      console.log(libName + ': token ' + t + ' received');
    }
  };

  noop = function(){};


  /**
   * 
   * @class
   *  
   * @param {Object} config - dictionary of configuration options
   * @param {Number} config.amount - purchase amount (¢)
   * @param {String} config.description - purchase item description
   * @param {String} config.mode - can be 'test' or 'live'
   * @param {Function} [_config.tokenHandler] - handle successful token 
   * 
   * @prop {Number} amount - purchase amount (in ¢)
   * @prop {Object} _config - configuration
   * @prop {String} [_config.key] - api key in use
   * @prop {String} [_config.mode] - determines api key: 'test' or 'live'
   * @prop {String} [_config.name] - merchant name (IvyInsights, Inc.)
   * @prop {String} [_config.testKey] - test api key
   * @prop {Function} [_config.tokenHandler] - handle successful token 
   *                                           response from api 
   * @prop {String} description - purchase item description
   *
   **/
  function PaymentPrompt(config){
    var elem, k, v;

    // support query string
    if (typeof elem === 'string'){
      elem = document.querySelector(elem);
    }

    // validate price
    if (!config.amount || typeof config.amount !== 'number'){
      console.warn(libName + ': amount must be a number');
    }

    // validate description
    if (!config.description || typeof config.description !== 'string'){
      console.warn(libName + ': description must be a string');
    }

    // set configs
    this._config = {};
    for (k in defaults){
      v = defaults[k];
      if (k in config){
        this._config[k] = config[k];
      } else{
        this._config[k] = v;
      }
    }

    this.amount = config.amount;
    this.description = config.description;

    // set api key
    if (this._config.mode !== 'live'){
      this._config.key = this._config.testKey;
    }
  }


  /**
   * Display the payment prompt
   * 
   * @function
   * @return {Object} - this PaymentPrompt instance 
   * 
   **/
  PaymentPrompt.prototype.show = function(){
    var handler, success;
    success = this._config.tokenHandler; 

    // create payment handler
    handler = StripeCheckout.configure({
      key: this._config.key,
      locale: 'auto',
      token: function(token) {
        success(token);
      }
    });
    
    // open payment handler
    handler.open({
      name: this._config.name,
      description: this.description,
      amount: this.amount
    });

    return this;
  };

  // export PaymentPrompt
  window.PaymentPrompt = PaymentPrompt;

})(document, window);