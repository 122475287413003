'use strict';

(function(undefined){

  var composeMessage;
  var composePath;
  var getTelegramId;
  var stupidBrowser;
  var invalidIdMessage;
  var startMessage;
  var telegramViewId;

  // Path to compose page
  composePath = '/';

  // HUD prompt for navigating to compose
  composeMessage = 'Want to compose your own telegram?<br>' +
    '<strong>Tap here to get started!</strong>';

  // Initial HUD message to display
  startMessage = 'You have received a telegram! <br>' +
    '<strong>Tap here to unfold it.</strong>';

  // Is the user using the latest and greatest internet browsing
  //   technology?
  stupidBrowser = document.documentMode ? true : false;

  invalidIdMessage = 'Oops, this telegram is missing. <br>' + 
    '<strong>Make sure you entered the URL correctly!</strong>';

  // ID of telegram view wrapper element
  telegramViewId = 'telegram';


  /**
   * @function
   * Return the unique telegram identifier from the address bar
   * @return {string} - telegram identifier
   **/
  getTelegramId = function(){
    return window.location.hash.substr(2, Telegram.idLength);
  };

  /** 
   * @class
   * The ReceiveController is responsible for managing the presentation
   *   of a received telegram to the operator.
   * @param {object} [options] 
   *
   * @prop {object} client - EssexClient instance responsible for 
   *   sending GET telegram requests to the Essex API
   * @prop {object} hudView - Puffin HUD view
   * @prop {bool | undefined} isUnfolded - is the telegram unfolded
   * @prop {string} telegramId - telegram identifier
   * @prop {object | null} telegramStaðView - TelegramStaðView
   **/
  function ReceiveController(options){
    var self;
    
    options = options || {};
    self = this;
    this.telegramId = getTelegramId();
    this.client = new EssexClient({
      onGet: function(error, data){
        self._onGetTelegram(error, data);
      },
      version: app.environment === 'development' ? 'staging' : 'v1'
    });
  } 

  /**
   * @function
   * Handles get telegram request respones. Parses returned telegram
   *   data and renders a telegram view if successful.
   **/
  ReceiveController.prototype._onGetTelegram = function(error, data){
    var plaintext;
    var self;

    self = this;
    this.hudView = new Puffin();

    if (error){
      this.hudView.says(invalidIdMessage, null, 'bashful');
    } else{
      this.telegram = new Telegram({ustty: data});
      plaintext = this.telegram.getPlaintext({exclude: 'design'});
      this.telegramStaðView = new TelegramStaðView(plaintext, {
        autoindent: false,
        design: this.telegram.design,
        timestamp: new Date(),
        width: Math.min(screen.width, 700)
      }, {
        folded: !stupidBrowser,
        vaðmálId: telegramViewId
      });
      if (!stupidBrowser){
        this.hudView.says(startMessage, function(){
          self._onUnfold();
        });
        this.telegramStaðView.vaðmál.addEventListener('click', function(){
          self._onUnfold();
        });
      } else{
        this.isUnfolded = true;
        this.hudView.says(composeMessage, function(){
          window.location.hash = '';
          window.location.pathname = composePath;
        });
      }
    }
  };

  /**
   * @function
   * Code to run once the operator has unfolded the telegram
   **/
  ReceiveController.prototype._onUnfold = function(){
    if (this.isUnfolded){
      return;
    }
    this.telegramStaðView.unfold();
    this.hudView.says(composeMessage, function(){
      window.location.hash = '';
      window.location.pathname = composePath;
    });
    this.isUnfolded = true;
  };

  /**
   * @function
   * Code to run once the appropriate DOM elements have loaded. Makes
   *   telegram data fetch request.
   **/
  ReceiveController.prototype.viewDidLoad = function(){
    this.client.getTelegram(this.telegramId);
  };


  // Export module
  window.ReceiveController = ReceiveController;

})();