'use strict';

(function(){
  var defaultCallback;
  var defaults;
  var baseEndpoint;
  var testEndpoint;
  var liveEndpoint;
  var libName;

  libName = 'IntrepidClient';
  baseEndpoint = 
    'https://zyq0x4cxme.execute-api.us-west-2.amazonaws.com/';
  liveEndpoint = baseEndpoint + 'live';
  testEndpoint = baseEndpoint + 'test';


  defaultCallback= function(error, response){
    console.warn(libName + ': unhandled response from Intrepid API.' +
      '\nerror: ' + error + '\nresponse: ' + response);
  };

  defaults = {
    callback: defaultCallback,
    mode: 'test'
  };

 /**
  * Client for the Intrepid AWS API Gateway
  *
  * @class
  * 
  * @param {object} [config] - configurations
  * 
  * @prop {function} callback - request response handler
  * @prop {string} endpoint - API endpoint
  * @prop {string} mode - 'live' or 'test' Stripe charge
  * 
  **/
  function IntrepidClient(config){
    config = config || {};
    for (var key in defaults){
      if (typeof config[key] === undefined){
        config[key] = defaults[key];
      }
    }

    this.callback = config.callback;
    this.mode = config.mode;

    this.endpoint = 
      this.mode === 'live' ? liveEndpoint : testEndpoint;
  }

 /**
  * Create a charge with the given Stripe token
  *
  * @function
  * 
  * @param {string} token - Stripe token
  * 
  * @return {object} this IntrepidClient instance
  **/
  IntrepidClient.prototype.createCharge = function(token){
    var callback;
    callback = this.callback;
    $.ajax({
      type : 'POST',
      url  : this.endpoint,
      data : JSON.stringify({ 'token' : token }),
      contentType : 'application/json; charset=utf-8',
      dataType : 'json',
      encode : true
    })
    .done(function(data, textStatus, jqXHR){
      // handle error response
      if (data.statusCode !== 200){
        console.warn(libName + ': create charge failed.');
        console.warn(data);
        console.warn(textStatus);
        console.warn(jqXHR);
        callback(data.body);
      }
      // handle success 
      else {
        callback(null, data.body);
      }
    });
    return this;
  };

  // Export IntrepidClient
  window.IntrepidClient = IntrepidClient;

})();