'use strict';

function Avatar(config){
  var self = this;
  var elem = (config && config.elem) || $('#avatar');
  this.emotion = null;

  // Add emotion class to avatar element. If `duration` is 
  //   specified, remove the class after `duration` milliseconds.
  this.emote = function(emotion, duration){
    if (emotion === 'bashful' || 
      emotion === 'happy' || 
      emotion === 'sad') {
      self.emotion = emotion;
      elem.addClass(emotion);
      if (typeof duration !== 'undefined'){
        setTimeout(function(){
          self.emote(null);
        }, duration);
      }
    } else if (emotion === null){
      elem.removeClass(self.emotion);
      self.emotion = null;
    }
    else {
      throw 'got invalid emotion: ' + emotion;
    }
  };
}

window.Avatar = Avatar;