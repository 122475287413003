'use strict';

(function(undefined){
  var baseName;
  var invalidInputMessage;
  var invisibleInputId;
  var libName;
  var messagePrefix;
  var milestones;
  var nextButtonId;
  var nextStepMessage;
  var placeholderText;
  var playSound;
  var sfxPath;
  var sounds;
  var startMessage;
  var telegramWrapperId;

  libName = 'Compose Controller';
  baseName = libName.toLowerCase().replace(' ', '-');

  // HUD message to display if the operator types an invalid character
  invalidInputMessage = '<strong>Oops!</strong> ' +
    'The letter you typed <br>cannot be sent by telegram. Sorry!';

  // <input> to trigger virtual keyboard display
  invisibleInputId = 'invisible-input';

  // text to prepend to the message
  messagePrefix = 'TO ';
  
  // HUD message to display once the operator has begun typing
  nextStepMessage = '<strong>Share your telegram with friends!' +
    '</strong><br> Tap here when you\'re ready.';

  // ID of next button element
  nextButtonId = 'next-ribbon-button';

  // Telegram text to display before the operator begins typing
  placeholderText = [
    'WELCOME TO THE CUTTING EDGE OF OBSOLESCENCE STOP=',
    'HERE YOU MAY CREATE AND SHARE YOUR OWN VIRTUAL TELEGRAMS... ',
    'FOR FREE STOP= '
  ].join('');

  if (screen.width > screen.height){
    placeholderText += 'TO BEGIN, JUST START TYPING!';
  } else{
    placeholderText += 'TO BEGIN, JUST TAP HERE!';
  }


  // Path to sound effects assets
  sfxPath = 'assets/sfx/';

  // HUD message to display before the operator begins typing
  if (screen.width > screen.height){
    startMessage = 'Create and share retro telegrams!<br>' +
      '<strong>To begin, just start typing.</strong>';
  } else {
    startMessage = 'Create and share retro telegrams!<br>' +
      '<strong>To begin, just tap the telegram.</strong>';
  }

  // ID of telegram wrapper element
  telegramWrapperId = 'telegram-wrapper';

  /**
   * @namespace
   * Milestones in the operator's journey.
   * @prop {string} start - initial state
   * @prop {string} composing - the operator is entering telegram text
   **/
  milestones = {
    start: 'start',
    compose: 'composing'
  };

  /**
   * @namespace
   * @prop {string} keyUp - sound to play on key up
   * @prop {string} keyDown - sound to play on key down
   * @prop {string} bell - bell sound
   **/
   sounds = {
    keyUp: 'key-up',
    keyDown: 'key-down',
    bell: 'BELL'
   };

  /**
   * @function
   * Play sound with given name.
   * @param {string} name - sound name
   **/
  playSound = function(name){
    ion.sound.play(name);
  };

  /**
   * @class
   * The ComposeController is responsible for managing the UI of the 
   *   compose scene. It captures operator input, renders it on the
   *   telegram view; it guides the operator through the composing 
   *   process by displaying messages on the HUD view. 
   * 
   * @prop {string} currentMilestone - represents the discrete stage
   *   of the operator's journey through the scene.
   * @prop {object} essexClient - EssexClient instance responsible for
   *   communicating with the Essex API. For more information, see:
   *   https://bitbucket.org/ivyinsights/essex
   * @prop {object} hud - HUD view for guiding the operator through
   *   the composing process.
   * @prop {object} inputElement - <input> element for capturing
   *   operator input.
   * @prop {string} messageBuffer - text entered by the operator
   * @prop {object} nextButton - RibbonButtonView to advance to the
   *   next step
   * @prop {object} telegram - Telegram object that holds the 
   *   up-to-date data of the operator's telegram
   * @prop {object} telegramView - Telegram (see Hoe) object responsible
   *   for rendering a telegram to the operator
   **/
  function ComposeController(){
    var self;
    var isTest;

    self = this;
    isTest = window.location.host.match(/^localhost/) && true;

    this.inputElement = document.getElementById(invisibleInputId);
    this.inputElement.addEventListener('input', function(){
      self._onTextEntry();
    });

    this.telegram = new Telegram({design: null});
    this.messageBuffer = placeholderText;
    this.essexClient = new EssexClient({
      onPut: function(){
        self._onFinish();
      },
      version: app.environment === 'development' ? 'staging' : 'v1'
    });
    this.nextButton = new RibbonButtonView(
      document.getElementById(nextButtonId), {
        isHidden: true,
        onClick: function(){
          self._onSubmit();
        },
        position: 'right',
        text: 'Next'
      }
    );
    this.nextButton.render();


    // Initialize sound effects
    ion.sound({
      sounds: [
        {
          name: sounds.bell
        },
        {
          name: sounds.keyDown
        },
        {
          name: sounds.keyUp
        }
      ],
      volume: 1,
      path: sfxPath,
      preload: true,
      multiplay: true
    });
  }


  /**
   * @function
   * Operator is done composing.
   **/
  ComposeController.prototype._onFinish = function(){
    window.location.href = '/catalog/#/' + this.telegram.id;
  };

  /**
   * @function
   * PUT current telegram data to Essex API.
   **/
  ComposeController.prototype._onSubmit = function(){
    var base64;
    var id;
    var txt;

    txt = this.telegram.getPlaintext();
    base64 = this.telegram.getUSTTY('base64');
    id = this.telegram.id;

    ga('send', 'event', 'compose', 'finish', null, txt.length);

    this.essexClient.putTelegram({id: id, signal: base64});
  };

  /** 
   * @function
   * Handle backspace key press.
   **/
  ComposeController.prototype._onBackspace = function(){
    var len;
    len = this.messageBuffer.length;
    this._updateMessage(this.messageBuffer.substring(0, len - 1));
    this.inputElement.value = this.messageBuffer;
  };

  /** 
   * @function
   * Handle key down event. Note that the key down event triggers
   *   whenever *any* key is pressed.
   **/
  ComposeController.prototype._onKeyDown = function(e){
    var keyCode;
    keyCode = e.keyCode;

    // handle backspace
    if (keyCode === 8){
      this._onBackspace();
      e.preventDefault();
    }
    // handle newline
    if (keyCode === 13 || keyCode === 10){
      playSound(sounds.bell);
    }
    // handle space
    if (keyCode === 32){
      this._onKeyPress(e);
    }
    // handle arrow keys
    if (keyCode === 37 || keyCode === 38 || keyCode === 39 ||
      keyCode === 40){
      e.preventDefault();
    }

    playSound(sounds.keyUp);
  };

  /**
   * @function
   * Handle key press event. Note that the key press event is only
   *   triggered when a printable character key is pressed.
   **/ 
  ComposeController.prototype._onKeyPress = function(){
    playSound(sounds.keyDown);
  };

  /** 
   * @function
   * Handle key up event. 
   **/
  ComposeController.prototype._onKeyUp = function(){
    playSound(sounds.keyUp);
  };

  /**
   * @function
   * Handle operator text input.
   **/
  ComposeController.prototype._onTextEntry = function(){
    var str;
    str = this.inputElement.value;
    this._updateMessage(str);
    this.inputElement.value = this.messageBuffer;
  };

  /** 
   * @function
   * Handle operator changes to the telegram's message.
   **/
  ComposeController.prototype._onMessageUpdate = function(){
    this.telegramView.updateText(this.telegram
      .getPlaintext());
  };


  /**
   * @function
   * @param {string} str - proposed new value for messageBuffer
   **/
  ComposeController.prototype._updateMessage = function(str){
    var proposed;
    var len;
    var oldBody;

    if (str === ''){
      this._reachMilestone(milestones.start);
      return;
    } else{
      this._reachMilestone(milestones.compose);
    }

    len = messagePrefix.length;
    proposed = [messagePrefix, 
      str].join('').toUpperCase().replace(/\n/g, '=');
    oldBody = this.telegram.body;
    this.telegram.body = proposed;
    if (!this.telegram.isUSTTYCompliant()){
      this.hud.warns(invalidInputMessage);
      console.warn(libName + ': `' + str + '` is not valid input.');
      this.telegram.body = oldBody;
    }

    this.messageBuffer = this.telegram.body.substr(len);
    this._onMessageUpdate();
  };

  /**
   * @function
   * Handles operator reaching different milestones
   * @param {string} milestone - name of milestone
   **/
  ComposeController.prototype._reachMilestone = function(milestone){
    var self;

    self = this;

    if (this.currentMilestone === milestone){
      return;
    }

    switch(milestone){
      case milestones.start:
        this.messageBuffer = '';
        this.telegram.body = placeholderText;
        this.telegramView.updateText(placeholderText);
        this.hud.says(startMessage);
        this.nextButton.hide();
        break;
      case milestones.compose:
        ga('send', 'event', 'compose', '(re)start');
        this.telegram = new Telegram({design: null});
        this.hud.hide();
        this.nextButton.unhide();
        break;
      default:
        console.warn(libName + ': unknown milestone ' + milestone);
        return;
    }

    this.currentMilestone = milestone;
  };
  
  /**
   * @function
   * Code to run once the compose scene is displayed to the operator.
   *   Initializes all listeners.
   **/
  ComposeController.prototype.viewDidAppear = function(){
    var self;

    self = this;

    document.onkeypress = function(e){
      self._onKeyPress(e);
    };
    document.onkeydown = function(e){
      self._onKeyDown(e);
    };
    document.onkeyup = function(e){
      self._onKeyUp(e);
    };

    this.inputElement.focus();
    
  };

  /**
   * @function
   * Code to run once the compose scene is no longer displayed to the 
   *   operator. Destroys all listeners.
   **/
  ComposeController.prototype.viewDidDisappear = function(){
    document.onkeypress = null;
    document.onkeydown = null;
    document.onkeyup = null;
  };

  /**
   * @function
   * Code to run once the appropriate DOM elements has loaded. 
   *   Initializes all views.
   **/
  ComposeController.prototype.viewDidLoad = function(){
    var self;

    self = this;
    window.location.hash = '';

    this.telegramView = this.telegramView || 
      new TelegramView(this.messageBuffer, {
        autoindent: false,
        vaðmálId: 'telegram',
        width: Math.min(screen.width, 700)
      });
    document.getElementById('telegram')
    .addEventListener('click', function(){
      var elem;
      elem = document.getElementById(invisibleInputId);
      elem.focus();
    });
    this.hud = this.hud || new Puffin();

    if (this.inputElement.value !== ''){
      this._updateMessage(this.inputElement.value);
      this._reachMilestone(milestones.composing);
    }  else{
      this._reachMilestone(milestones.start);
    }

  };

  // Export module
  window.ComposeController = ComposeController;

})();