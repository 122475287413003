'use strict';

(function(window, document, undefined){
  
  var CLASS_NAME; // Class name
  var DEFAULTS; // Default constructor parameters
  var LIBNAME; // Name of this module
  var TELEGRAM_ID_BASE;
  
  var WARN;

  CLASS_NAME = 'catalog-item-view';
  LIBNAME = 'CatalogItemView';
  TELEGRAM_ID_BASE = 'catalog-item-';

  DEFAULTS = {
    onClick: function(i){
      console.log('[' + LIBNAME + ']: catalog item #' + i + 
        '\'s buy button clicked');
    },
    telegramDesign: null,
    telegramWidth: null
  };

  /**
   * @function 
   * Print the given message as a console warning
   **/
  WARN = function(msg){
    console.warn('[' + LIBNAME + ']: ' + msg);
  };

  /**
   * @class 
   * Catalog Item View
   *
   * @param {object | string} elem - HTML element or query selector
   * @param {object} params - parameters
   * @param {number} params.amount - catalog item price
   * @param {number} params.index - catalog item index
   * @param {string} params.telegramDesign - telegram design
   * @param {string} params.telegramText - telegram display text
   * @param {number} params.telegramWidth - telegram view width
   * 
   * @prop {object} elem - HTML container element
   * @prop {number} amount - catalog item price
   * @prop {number} index - catalog item index
   * @prop {function} onClick - button click handler
   * @prop {string} telegramDesign - telegram design
   * @prop {string} telegramText - telegram display text
   * @prop {number} telegramWidth - telegram view width
   **/
  function CatalogItemView(elem, params){
    params = params || {};

    // Set `elem` property
    if (typeof elem === 'string'){
      this.elem = document.querySelector(elem);
    } else{
      this.elem = elem;
    }

    if (!(this.elem && this.elem.nodeType === 1)) {
      WARN('First argument must be a DOM element');
    }
    this.elem.classList.add(CLASS_NAME);

    // Set `index` property
    this.index = params.index;

    if (!((this.index || this.index === 0) && 
      (typeof this.index === 'number'))){
      WARN('`index` parameter must be a number, but got ' +
        params.index);
    }

    // Set `telegramText` property
    this.telegramText = params.telegramText;
    if (!(this.telegramText && (typeof this.telegramText === 'string'))){
      WARN('`telegramText` parameter must be a string, but got ' +
        params.telegramText);
    }

    // Set `telegramDesign` property
    this.telegramDesign = params.telegramDesign || 
      DEFAULTS.telegramDesign;

    // Set `telegramWidth` property
    this.telegramWidth = params.telegramWidth || DEFAULTS.telegramWidth;

    // Set `amount` property
    this.amount = params.amount;
    if (!((this.amount || this.amount === 0) && 
      (typeof this.amount === 'number'))){
      WARN('`amount` parameter must be a string, but got ' +
        params.amount);
    }

    // Set `onClick` property
    this.onClick = params.onClick || DEFAULTS.onClick;
    if (!(this.onClick && (typeof this.onClick === 'function'))){
      WARN('`onClick` parameter must be a function, but got ');
    }

    // Render 
    this.render();
    
  }

  /**
   * @function
   * Render the view inside the `elem` container
   **/
  CatalogItemView.prototype.render = function(){
    var buyBtn;
    var self = this;
    var telEl;

    // Clear the container element
    this.elem.innerHTML = '';

    // Create the telegram view container element
    telEl = document.createElement('DIV');
    telEl.id = TELEGRAM_ID_BASE + this.index;
    this.elem.appendChild(telEl);

    // Render the telegram view
    new TelegramView(this.telegramText, {
      design: this.telegramDesign,
      timestamp: new Date(),
      vaðmál: telEl,
      width: this.telegramWidth
    });

    // Add the button view
    buyBtn = new BuyButtonView({
      amount: this.amount,
      onClick: function(){
        self.onClick(self.index);
      }
    });
    telEl.appendChild(buyBtn.render());

  };

  //Export module
  window.CatalogItemView = CatalogItemView;


})(window, document);