'use strict';

function HUD(config){
  var self = this;
  var selector = (config && config.selector) || '#hud';
  var clickHandler = (config && config.onClick) || null;
  var elem = $(selector);
  var avatar = new Avatar({elem: $(selector + ' .pufflavík')});

  function handleClick(){
    if (typeof self.onClick === 'function'){
      self.onClick();
    }
  }

  // Add or remove `'clickable'` class. This determines
  //   whether or not the HUD will react when the user 
  //   hovers over it.
  this.setClickable = function(b){
    if (b){
      elem.addClass('clickable');
    }
    else {
      elem.removeClass('clickable');
    }
  };

  // Set the handler called when the HUD is clicked
  this.setOnClick = function(f){
    this.onClick = f;
    this.setClickable(typeof f === 'function');
  };

  // Update the innerHTMl of the submit button's callout.
  this.setMessage = function(html){
    $(selector + ' .callout').html(html);
  };

  // Hide the HUD
  this.hide = function(completion){
    elem.addClass('hide');
    setTimeout(completion, 200);
  };

  // Show the HUD
  this.show = function(completion){
    elem.removeClass('hide');
    setTimeout(completion, 200);
  };

  // Shake the HUD
  this.shake = function(){
    elem.addClass('shake');
    setTimeout(function(){
      elem.removeClass('shake');
    }, 1000);
  };

  // Display an emotion
  this.displayEmotion = function(emotion, duration){
    avatar.emote(emotion, duration);
  };

  // Detect clicks
  this.setOnClick(clickHandler);
  elem.on('click', handleClick);
}

window.HUD = HUD;