'use strict';

(function(window, document, undefined){
  
  var CLASS_NAME; // Class name
  var DEFAULTS; // Default constructor parameters
  var LIBNAME; // Name of this module
  var SPIN_CLASS_NAME;

  var WARN;

  CLASS_NAME = 'dodecagon-button';
  DEFAULTS = {
    href: null,
    onClick: null,
    spin: false
  };
  LIBNAME = 'DodecagonButtonView';
  SPIN_CLASS_NAME = 'spin';

  /**
   * @function 
   * Print the given message as a console warning
   **/
  WARN = function(msg){
    console.warn('[' + LIBNAME + ']: ' + msg);
  };

  /**
   * @class 
   * DodecagonButtonView
   *
   * @param {object} params - parameters
   * @param {string} [params.image] - image url for button content  
   * @param {string} [params.href] - button link
   * @param {function} [params.onClick] - click handler
   * 
   **/
  function DodecagonButtonView(params){
    params = params || {};

    this.href = params.href || DEFAULTS.href;
    this.image = params.image;
    this.onClick = params.onClick || DEFAULTS.onClick;
    this.spin = params.spin || DEFAULTS.spin;

    if (this.href){
      var href = this.href;
      this.onClick = function(){
        window.location = href;
      };
    }

  }

  /**
   * @function
   * Render this buy button view
   * @return {object} - HTML 'A' element
   **/
  DodecagonButtonView.prototype.render = function(){
    var wrapper;
    var content;
    var background;

    wrapper = document.createElement('DIV');
    background = document.createElement('DIV');
    content = document.createElement('DIV');

    wrapper.classList.add(CLASS_NAME);
    wrapper.appendChild(background);
    wrapper.appendChild(content);

    if (this.onClick){
      wrapper.addEventListener('click', this.onClick);
    }

    if (this.spin){
      wrapper.classList.add(SPIN_CLASS_NAME);
    }

    background.style.backgroundImage = 'url(' + this.image + ')';

    return wrapper;
  };

  //Export module
  window.DodecagonButtonView = DodecagonButtonView;


})(window, document);