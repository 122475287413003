'use strict';

(function(document, window, undefined){
  var CLASSES;
  var DEFAULTS;
  var LIBNAME;
  var NOOP;
  var WARN;

  /**
   * @namespace
   * @prop {string} container - CSS class name for outer container
   * @prop {string} isHidden - CSS class name used to hide the 
   *   button (animated)
   * @prop {string} isOffScreen - CSS class name used to hide the 
   *   button (no animation)
   * @prop {string} positionLeft - CSS class name used to position 
   *   the button on the lefthand side
   * @prop {string} positionRight - CSS class name used to position
   *   the button the righthand side
   **/
  CLASSES = {
    container: 'ribbon-button-view',
    isHidden: 'hidden',
    isOffScreen: 'off-screen',
    positionLeft: 'left',
    positionRight: 'right'
  };

  /**
   * @namespace
   * @prop {string} href - default href attribute for the button
   * @prop {function | null} onClick - default click handler for the
   *   button
   * @prop {string} position - default position. can be 'left' or 
   *   'right' 
   **/
  DEFAULTS = {
    href: '',
    isHidden: false,
    onClick: null,
    position: 'left'
  };

  LIBNAME = 'RibbonButtonView';

  NOOP = function(){};


  /**
   * @function
   * Print the given warning message to the console
   * @param {string} str - message
   **/
  WARN = function(str){
    if (console){
      console.warn(LIBNAME + ': ' + str);
    }
  };

  /**
   * @class
   * Renders a fixed ribbon-shaped button in either the upper-left or
   *   upper-right top edge of the screen. 
   * 
   * @param {object | string} elem - container HTML element (or query 
   *   string for container HTML element) 
   * @param {object} options - options
   * @param {string} [options.href] - button href attribute
   * @param {function} [options.onClick] - click handler
   * @param {string} [options.position] - which screen edge 'left' or 
   *   to render the button near'right'. 
   * @param {string} options.text - text to render
   * 
   * @prop {string} position - 'left' or 'right'
   * @prop {string} href - button href attribute
   * @prop {bool} isHidden - should the button be visible or hidden
   * @prop {function | null} onClick - click handler
   * @prop {string} text - text to display on the button
   **/
  function RibbonButtonView(elem, options){
    options = options || {};

    // find the container element
    if (typeof elem === 'string'){
      this.elem = document.querySelector(elem);
    } else {
      this.elem = elem;
    }
    if (!(this.elem && this.elem.nodeType === 1)) {
      if (typeof console !== 'undefined') {
        WARN('First argument must be a DOM element');
      }
      return;
    }

    this.text = options.text;
    if (typeof this.text !== 'string'){
      WARN('`text` must be a String');
    }
    this.position = options.position || DEFAULTS.position;
    this.onClick = options.onClick || DEFAULTS.onClick;
    this.href = options.href || DEFAULTS.href;
    this.isHidden = options.isHidden || DEFAULTS.isHidden;

  }

  /**
   * @function
   * Hides the button.
   * @return {object} - this instance
   **/
  RibbonButtonView.prototype.hide = function(){
    this.isHidden = true;
    this.elem.classList.add(CLASSES.isHidden);
    return this;
  };

  /**
   * @function
   * Renders the button view within the container element
   * @return {object} this RibbonButtonView instance
   **/
  RibbonButtonView.prototype.render = function(){
    var view; 

    view = this;
    

    this.elem.className = this._containerClassList().join(' ');

    if (this.onClick){
      this.elem.addEventListener('click', function(e){
        e.preventDefault();
        view.onClick();
      });
    }

    this.elem.innerHTML = this._buildInnerHTML();

    return this;
  };

  /**
   * @function
   * Reveals the button.
   * @return {object} - this instance
   **/
  RibbonButtonView.prototype.unhide = function(){
    this.isHidden = false;
    this.elem.classList.remove(CLASSES.isHidden, CLASSES.isOffScreen);
    return this;
  };


  /**
   * @function
   * Generates the inner HTML used to render the ribbon button view
   * @return {string} the inner HTML
   **/
  RibbonButtonView.prototype._buildInnerHTML = function(){
    return '<a href="' + this.href + '">' + this.text + '</a>' +
      '<div></div>';
  };

  /** 
   * @function
   * Return the correct list of class names for the outermost
   *   container.
   * @return {string[]} - class name list
   **/
  RibbonButtonView.prototype._containerClassList = function(){
    var classList;

    classList = [];

    switch(this.position){
      case('left'):
        classList.push(CLASSES.positionLeft);
        break;
      case('right'):
        classList.push(CLASSES.positionRight);
        break;
      default:
        WARN('`position` must be "left" or "right", but got ' +
          this.position);
    }

    classList.push(CLASSES.container);
    if (this.isHidden){
      classList.push(CLASSES.isOffScreen);
      classList.push(CLASSES.isHidden);
    }

    return classList;
  };


  // export module
  window.RibbonButtonView = RibbonButtonView;

})(document, window);