'use strict';

(function(document, window, undefined){
  var upgradeBtnId;

  upgradeBtnId = 'upgrade-171222';

  function ShareScreen(config){
    var self = this;
    this.elem = document.getElementById('share-modal');
    // Callback to run on hide()
    this.onHide = (config && config.onHide);
    // Callback to run when the operator presses purchase
    this.onPurchase = (config && config.onPurchase);

    // Show the overlay
    this.show = function(completion){
      self.elem.classList.remove('hide');
      setTimeout(completion, 200);
    };
    // Hide the overlay
    this.hide = function(completion){
      self.elem.classList.add('hide');
      setTimeout(completion, 200);
    };
    // Display the given short URL
    this.setShortURL = function(shortURL){
      document.getElementById('short-url').value = shortURL;
    };
    // Handle a click outside the main area
    function handleClick(e){
      if(!$(event.target).closest('.share-content').length) {
        if($('.share-content').is(':visible')) {
          e.preventDefault();
          self.hide(self.onHide);
        }
      } 
    }
    // Detect click on purchase button
    document.getElementById(upgradeBtnId)
      .addEventListener('click', function(e){
        e.preventDefault();
        self.onPurchase();
      });
    // Detect a click outside the main area
    this.elem.addEventListener('click', handleClick);
    // Share URL
    if (config && config.shortURL){
      this.setShortURL(config.shortURL);
    }
  }

  window.ShareScreen = ShareScreen;

})(document, window);

