'use strict';

(function(document, window, undefined){
  
  var ENVIRONMENTS;
  var GET_CURRENT_ENVIRONMENT;
  var LIBNAME;
  var WARN;

  /**
   * Hostname(s) for staging, development, and production environments
   * @namespace
   * @prop {string[]} development - development environment hostname(s)
   * @prop {string[]} production - production environment hostname(s)
   * @prop {string[]} staging - production environment hostname(s)
   */
  ENVIRONMENTS = {
    development: ['localhost', 'ngrok'],
    production: ['wirelesstelegrams.com'],
    staging: ['staging.posthaste.is']
  };

  /**
   * Name of this module
   * @constant {string}
   */
  LIBNAME = 'AppController';

  /**
   * Return the current environment
   * @function
   * @return {string} - 'development', 'staging', or 'production' 
   */
  GET_CURRENT_ENVIRONMENT = function(){
    var currentHost;
    var hosts;

    currentHost = window.location.host;
    for (var env in ENVIRONMENTS){
      hosts = ENVIRONMENTS[env];
      for (var i = 0; i < hosts.length; i++){
        if (currentHost.includes(hosts[i])){
          return env;
        }
      }
    }
    WARN('Unknown host \'' + window.location.host +
      '\' is not associated with any environment. ' +
      'Defaulting to \'development\'');
    return 'development';
  };

  /**
   * Print a warning message to the console
   * @function
   */
  WARN = function(msg){
    console.warn('[' + LIBNAME + '] ' + msg);
  };

  /**
   * App Controller class
   * @class
   * @prop {string} environment - current runtime environment. can be
   *   'development', 'staging', or 'production' 
   */
  function AppController(){
    this.environment = GET_CURRENT_ENVIRONMENT();
  }

  // Export module
  window.AppController = AppController;

})(document, window);