'use strict';

function Puffin(config){
  var self = this;
  var hud = new HUD();

  this.message = (config && config.message);
  this.onClick = (config && config.onClick);
  this.emotion = (config && config.emotion);

  this.onHide = null;   // Callback to run once the HUD has been 
                        //   hidden. There is only one callback
                        //   instance to prevent race conditions.

  this.warning = null;  // Is a warning being displayed? Prevents
                        //   other warnings from appearing.


  // Render a message on the HUD and set the HUD's
  //   `onClick` handler.
  this.says = function(html, onClick, emotion){
    var em = emotion || null;
    this.isHidden = false;
    this.message = html;
    this.onClick = onClick;
    this.emotion = em;
    this.onHide = function(){
      hud.displayEmotion(em);
      hud.setMessage(html);
      hud.setOnClick(onClick);
      hud.show();     
    };
    hud.hide(function(){
      if(typeof self.onHide === 'function'){
        self.onHide();
      }
    });
  };

  // Render a temporary warning on the HUD.
  this.warns = function(html){
    var dur = 3000;
    if (this.warning === null){
      var message = self.message;
      var onClick = self.onClick;
      var isHidden = this.isHidden;
      this.warning = html;
      this.onHide = null;
      this.says(html, null, 'bashful');
      setTimeout(function(){
        if (isHidden){
          self.hide(function(){
            self.message = message;
            self.onClick = onClick;
          });
        }else{
          self.says(message, onClick);
        }
        self.warning = null;
      }, dur);
    }
  };

  // Hide the HUD
  this.hide = function(completion){
    hud.hide(completion);
    this.isHidden = true;
  };

  // Show the HUD
  this.show = function(completion){
    hud.show(completion);
    this.isHidden = false;
  };
}

window.Puffin = Puffin;