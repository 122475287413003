'use strict';

(function(document, window, undefined){
  var animateOpacity;
  var animateTranslation;
  var baseName;
  var buildBottomÞing;
  var buildTopÞing;
  var buildWindowÞing;
  var classNames;
  var createElement;
  var defaults;
  var libName;
  var noop;
  var setTransition;
  var buildContentÞing;
  var createChildren;

  libName = 'EnvelopeView';
  baseName = 'envelope';
  
  /**
   * @namespace
   *
   * @prop {Number} aspect - envelope aspect ratio
   * @prop {Number} contentY - initial content translation (%) 
   * @prop {Number} distance - distance (%) between envelope and content
   *   after translation 
   * @prop {Number} envelopeY - initial envelope translation (%) 
   * @prop {Number} speed - animation duration in ms
   * @prop {Number} width - envelope width
   */
  defaults = {
    aspect: 6.5 / 3.625,
    contentY: 0,
    distance: 110,
    envelopeY: 0,
    speed: 1000,
    width: 700
  };

  /**
   * @namespace
   */
  classNames = {
    bottomSeamLayer: 'bottom-seam-layer',
    bottomÞing: 'bottom-þing',
    contentShadowLayer: 'content-shadow-layer',
    contentÞing: 'content-þing',
    edgeLayer: 'edge-layer',
    hueLayer: 'hue-layer',
    shaderLayer: 'shader-layer',
    textureLayer: 'texture-layer',
    topPaperÞing: 'top-paper-þing',
    topPrintLayer: 'top-print-layer',
    topÞing: 'top-þing',
    vaðmál: 'vaðmál',
    windowBumpLayer: 'window-bump-layer',
    windowHueLayer: 'window-hue-layer',
    windowShaderLayer: 'window-shader-layer',
    windowÞing: 'window-þing',
    þing: 'þing'
  };

  // add baseName to all class names
  for (var k in classNames){
    classNames[k] = baseName + '-' + classNames[k]; 
  }

  /**
   * Create a DIV element with the given class name
   *
   * @function
   * @param {String} className - class name
   * @returns {Object} DIV element
   */
  createElement = function(className){
    var element, str;
    str = classNames[className];
    element = document.createElement('DIV');
    if (!str){
      console.warn(libName + ': invalid class name ' + className);
    }
    element.className = classNames[className];
    return element;
  };

  /**
   * Create a DIV element with the given class name for
   * every class name in the given array. Append all 
   * DIV elements to the given element.
   * 
   * @function
   * @param {String} className - class name
   * @returns {Object} DIV element
   */
  createChildren = function(elem, a){
    var i;
    for (i = 0; i < a.length; i++){
      elem.appendChild(createElement(a[i]));
    }
    return elem;
  };

  /**
   * Set an element's transition property
   * 
   * @function
   * @param {Object} elem - element
   * @param {Number} speed - transition speed in ms
   * @return {Object} element
   *
   * **/
  setTransition = function(elem, speed){
    elem.style.transition = 'all ' + speed / 1000 + 's';
    return elem;
  };

  /**
   * Animate an element's opacity
   * 
   * @function
   * @param {Object} elem - element
   * @param {Number} speed - transition speed in ms
   * @param {Number} opacity - opacity (between 0 and 1)
   * @return {Object} element
   *
   * **/
  animateOpacity = function(elem, speed, opacity){
    setTransition(elem, speed);
    elem.style.opacity = opacity;
    return elem;
  };

  /**
   * Animate an element's position
   * 
   * @function
   * @param {Object} elem - element
   * @param {Number} speed - transition speed in ms
   * @param {Number} x - x translation in %
   * @param {Number} y - y translation in %
   * @return {Object} element
   *
   * **/
  animateTranslation = function(elem, speed, x, y){
    setTransition(elem, speed);
    elem.style.transform = 'translateX(' + x + '%) translateY(' + y + 
      '%) rotateX(0deg)';
    return elem;
  };

  /**
   * Noop
   * @function
   **/
  noop = function(){};

  /**
   * Creates a new EnvelopeView object
   * @class
   *
   *
   * @param {String | Object} elem - html element that will become an
   *   EnvelopeView view. Can be a selector string or an element
   * @param {Object} [config] - options
   * @param {Number} [config.width] - envelope width
   * 
   * @prop {Object} vaðmál - outermost wrapper element
   * @prop {Object} _config - envelope configurations
   * @prop {Object} _contentShadowLayer - content shadow layer element
   * @prop {Object} _contentÞing - envelope content container element
   * 
   */
  function EnvelopeView(elem, config){
    var þ, v;
    // support selector strings as well as DOM elements
    if (typeof elem === 'string'){
      elem = document.querySelector(elem);
    }
    // validate element
    if (!(elem && elem.nodeType)){
      console.warn(libName + ': First argument must be a DOM element');
    }

    // set configs
    config = config || {};
    this._config = {};
    for (k in defaults){
      v = defaults[k];
      if (k in config){
        this._config[k] = config[k];
      } else{
        this._config[k] = v;
      }
    }

    // set the wrapper
    this.vaðmál = elem;
    // animation lock 
    this._isAnimating = false;

    // set size
    this.resize(this._config.width);

    // create content container
    this._contentÞing = buildContentÞing();
    this._contentShadowLayer = this._contentÞing.children[0];

    // set initial content and envelope positions
    animateTranslation(this._contentÞing, 0, 0, this._config.contentY);
    animateTranslation(this.vaðmál, 0, 0, this._config.envelopeY);

    // create outer container and append children
    þ = createElement('þing');
    þ.appendChild(buildTopÞing());
    þ.appendChild(buildWindowÞing());
    þ.appendChild(this._contentÞing);
    þ.appendChild(buildBottomÞing());

    // append outer container to outer wrapper
    elem.classList.add(classNames['vaðmál']);
    elem.appendChild(þ);
  }

  /**
   * Animate pulling the envelope content up while the envelope rem-
   * ains stationary
   * 
   * @function
   * @returns the envelope object
   *
   **/  
  EnvelopeView.prototype.openUp = function(callback){
    var csl, elem, speed, y;
    csl = this._contentShadowLayer;
    elem = this._contentÞing;
    speed = this._config.speed;
    y = -this._config.distance;

    this._queueAnimation(function(){
      animateTranslation(elem, speed, 0, y);
      animateOpacity(csl, speed, 0);
    }, callback);

    setTimeout(callback || noop, speed);

    return this;
  };

  /**
   * Animate pulling the envelope down while the envelope content rema-
   * ins stationary
   * 
   * @function
   * @returns the envelope object
   *
   **/  
  EnvelopeView.prototype.openDown = function(callback){
    var csl, elem, speed, vaðmál, y;
    csl = this._contentShadowLayer;
    elem = this._contentÞing;
    speed = this._config.speed;
    y = this._config.distance;
    vaðmál = this.vaðmál;

    this._queueAnimation(function(){
      animateTranslation(vaðmál, speed, 0, y);
      animateTranslation(elem, speed, 0, -y);
      animateOpacity(csl, speed, 0);
    }, callback);

    setTimeout(callback || noop, speed);

    return this;
  };

 /**
   * Run animations iff no other animations are currently running; handle
   * animation completion.
   * 
   * @function
   * @param {Function} animations - animation(s) to be run
   * @param {Function} [callback] - callback to run on animation completion
   * @returns the envelope object
   *
   **/  
  EnvelopeView.prototype._queueAnimation = function(animations, callback){
    var self = this;
    if (this._isAnimating){
      console.warn(libName + ': animation already in progress');
    } else{
      this._isAnimating = true;
      (animations || noop)();
      setTimeout(function(){
        self._isAnimating = false;
        (callback || noop)();
      }, this._config.speed);
    }
    return this;
  };


  /**
   * Insert an element into the envelope
   * 
   * @function
   * @param {Object} elem - element to be inserted (or selector string)
   * @param {Object} [options] - options
   * @param {String} [options.top] - top content offset. can be px or %
   * @param {String} [options.left] - left content offset. px or %
   * @returns the envelope object
   *
   **/
  EnvelopeView.prototype.insert = function(elem, options){
    // support selector strings as well as DOM elements
    if (typeof elem === 'string'){
      elem = document.querySelector(elem);
    }
    // validate element
    if (!(elem && elem.nodeType)){
      console.warn(libName + ': First argument must be a DOM element');
    }
    this._contentÞing.appendChild(elem);

    if (options && options.top){
      elem.style.top = options.top;
    }
    if (options && options.left){
      elem.style.left = options.left;
    }
    return this;
  };

  /**
   * Resize the envelope view.
   * 
   * @function
   * @param {Number} width - new width
   * @returns the envelope object
   *
   **/
  EnvelopeView.prototype.resize = function(width){
    this.vaðmál.style.width = width + 'px';
    this.vaðmál.style.height = width / defaults.aspect + 'px'; 
    return this;
  };

  /**
   * Create bottom container element and all children.
   * 
   * @function
   * @returns html element
   *
   **/
  buildBottomÞing = function(){
    var bþ, a;
    bþ = createElement('bottomÞing');
    a = ['hueLayer', 'textureLayer', 'bottomSeamLayer'];
    createChildren(bþ, a);
    return bþ; 
  };

  /**
   * Create content container and insert content
   * 
   * @function
   * @returns html element
   *
   **/
  buildContentÞing = function(){
    var cþ;
    cþ = createElement('contentÞing');
    cþ.appendChild(createElement('contentShadowLayer'));
    return cþ; 
  };

  /**
   * Create top container element and all children.
   * 
   * @function
   * @returns html element
   *
   **/
  buildTopÞing = function(){
    var tpþ, tþ, a;
    tpþ = createElement('topPaperÞing');
    tþ = createElement('topÞing');
    a = ['hueLayer', 'textureLayer', 'topPrintLayer', 'edgeLayer', 'shaderLayer'];
    createChildren(tpþ, a);
    tþ.appendChild(tpþ);
    return tþ;
  };

  /**
   * Create envelope window container element and all children.
   * 
   * @function
   * @returns html element
   *
   **/
  buildWindowÞing = function(){
    var wþ, a;
    a = ['windowHueLayer', 'windowShaderLayer','windowBumpLayer'];
    wþ = createElement('windowÞing');
    createChildren(wþ, a);
    return wþ;
  };


  // export EnvelopeView
  window.EnvelopeView = EnvelopeView;

})(document, window);