'use strict';

(function(document, window, undefined){
  /**
   * RegEx for telegram body
   * @constant {object}
   */
  var BODY_REGEX_STRING;
  /**
   * Default telegram configuration
   * @namespace
   * @prop {string} body - default telegram body text
   * @prop {string} design - default telegram design
   * @prop {string} usttyFormat - default format for USTTY input
   */
  var DEFAULTS;

  /** 
   * RegEx for telegram date (`timeOut`)
   * @constant {string}
   */
  var DATE_REGEX_STRING;

  /**
   * Format a given date correctly
   * @function
   * @param {object} d - Date object to be formatted.
   * @return {string} - formatted date or empty string
   */
  var FORMAT_DATE;

  /**
   * RegEx for telegram design
   * @constant {string}
   */
  var DESIGN_REGEX_STRING;

  /**
   * Format a given telegram design
   * @function
   * @param {string} design - telegram design
   * @return {string} - formatted telegram design
   */
  var FORMAT_DESIGN;

  /** 
   * Format the given telegram identifier
   * @function
   * @param {string} id - telegram identifier to be formatted
   * @reutn {string} - formatted id or empty string
   */
  var FORMAT_ID;

  /**
   * Generate the telegram's unique identifier
   * @function
   * @return {string} - a unique telegram identifier
   */
  var GENERATE_ID;

  /**
   * RegEx for Telegram identifier
   * @constant {string}
   */
  var ID_REGEX_STRING;

  /** 
   * The length of the telegram's identifier
   * @constant {number}
   */
  var ID_LENGTH;

  /**
   * Name of this library
   * @constant
   */
  var LIBNAME;

  /**
   * Characters that may not be included in this telegram's plaintext
   * @constant
   */
  var INVALID_CHARS_REGEX_STRING;

  /**
   * Month names
   * @constant {string[]}
   */
  var MONTH_NAMES;

  /**
   * Parse a telegram body from a given string
   * @function
   * @param {string} str - input string
   * @return {string} - body text
   */
  var PARSE_BODY;

  /**
   * Parse a telegram Date from a given string
   * @function
   * @param {string} str - input string
   * @return {object} - Date
   */
  var PARSE_DATE;

  /**
   * Parse a telegram design identifier from a given string
   * @function
   * @param {string} str - input string
   * @return {string} - design identifier
   */
  var PARSE_DESIGN;

  /**
   * Parse a telegram identifier from a given string
   * @function
   * @param {string} str - input string
   * @return {string} - identifier
   */
  var PARSE_ID;

  /**
   * Allowable characters in a telegram's unique identifier. Letters 
   *   that are easily mistaken (e.g. 0 and O) are omitted
   * @constant {string[]}
   */
  var VALID_ID_CHARS;

  /**
   * Print a warning message to the console
   * @function 
   * @param {string} msg - warning message to print
   **/
  var WARN;

  BODY_REGEX_STRING = '(.*)';

  DEFAULTS = {
    body: '',
    design: '180206',
    usttyFormat: 'data'
  };

  DATE_REGEX_STRING = 
    // day + February
    '(((([1-2][0-9]|[0-9]) FEB)|' + 
    // day + 30 day months
    '([0-9]|[1-2][0-9]|30]) (APR|JUN|SEP|NOV)|' +
    // day + 31 day months
    '([0-9]|[1-2][0-9]|3[0-1]) (JAN|MAR|MAY|JUL|AUG|OCT|DEC))' +
    // year
    ' [0-9][0-9]' + 
    // hour
    ' (1[0-2]|[1-9])' +
    // minute
    '[0-5][0-9]' +
    // am/pm
    'P?)';

  DESIGN_REGEX_STRING = '(\\([0-9]{6}\\))';

  FORMAT_DATE = function(d){
    var ampm;
    var dd;
    var hh;
    var mm;
    var mmm;
    var yy;

    if (!d){
      return '';
    }

    yy = (d.getFullYear() + '').slice(-2);
    dd = d.getDate();
    mmm = MONTH_NAMES[d.getMonth()];
    mm = d.getMinutes();
    ampm = '';
    hh = d.getHours();

    if (hh === 0){
      hh = 12;
    } else if (hh > 12){
      hh -= 12;
      ampm = 'P';
    }

    mm = mm < 10 ? '0' + mm : mm;

    return [dd, mmm, yy, [hh, mm, ampm].join('')].join(' ');
  };

  FORMAT_DESIGN = function(design){
    return design ? '(' + design + ')' : '';
  };

  FORMAT_ID = function(id){
    return id ? [id.slice(0,5), id.slice(-5)].join(' ') : '';
  };

    
  GENERATE_ID = function(){
    var id;
    var n; 
    var len;

    id = '';
    len = VALID_ID_CHARS.length - 1;

    for (var i = 0; i < ID_LENGTH; i++){
      n = Math.floor(Math.random() * Math.floor(len));
      id += VALID_ID_CHARS[n];
    }

    return id;
  };

  ID_LENGTH = 10;

  ID_REGEX_STRING = '[0-9A-Z]{5} [0-9A-Z]{5}';

  INVALID_CHARS_REGEX_STRING = 
    '[^A-Z0-9\\.,\\$#;: =\\(\\)\\-+\\\'\\"!\\?]';

  LIBNAME = 'Telegram';

  MONTH_NAMES = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 
    'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

  PARSE_BODY = function(str){
    var body;
    body = str
      // remove id and timestamp metadata from first line
      .replace(new RegExp(
        // telegram identifier
        '(' + ID_REGEX_STRING + '[=\\s]*)?' +
        // telegram timestamp (`timeOut`)
        '(' + DATE_REGEX_STRING + '[=\\s]*)?'
      ), '')
      // remove design identifier metadata
      .replace(new RegExp(
        DESIGN_REGEX_STRING + '[=\\s]*$'
      ), '');
    return (body && body !== '') ? body : null;
  };

  PARSE_DATE = function(str){
    var date;
    var day;
    var hour;
    var minute;
    var month;
    var parts;
    var pm;
    var year;

    str = new RegExp(DATE_REGEX_STRING).exec(str);

    if (str){
      parts = str[0].split(' ');

      // parse day
      day = parseInt(parts[0], 10);
      if (typeof day !== 'number'){
        WARN ('\'' + parts[0] + '\' is not a valid day');
      }

      // parse month
      for (var i = 0; i < 12; i++){
        if (parts[1] === MONTH_NAMES[i]){
          month = i;
        }
      }
      if (typeof month !== 'number') { 
        WARN ('\'' + parts[1] + '\' is not a valid month');
      }

      // parse year
      year = parseInt(parts[2], 10) + 2000;
      if (typeof year !== 'number'){
        WARN ('\'' + parts[2] + '\' is not a valid year');
      }

      // parse time
      pm = parts[3].slice(-1) === 'p';
      minute = parseInt(parts[3].replace(/[^0-9]/g, '').slice(-2), 10);
      hour = parseInt(parts[3].replace(/[^0-9]/g, '').slice(0, -2), 10);
      hour = pm && (hour < 12) ? hour + 12 : hour;
      hour = !pm && (hour === 12) ? 0 : hour;

      // set date
      date = new Date();
      date.setMonth(month);
      date.setDate(day);
      date.setYear(year);
      date.setHours(hour);
      date.setMinutes(minute);

      return date;
    } else{
      return null;
    }
  };

  PARSE_DESIGN = function(str){
    str = new RegExp(DESIGN_REGEX_STRING).exec(str);
    return str ? str[0].replace(/[^0-9]/g, '') : null;
  };

  PARSE_ID = function(str){
    str = new RegExp(ID_REGEX_STRING).exec(str);
    return str ? str[0].replace(/[^A-Z0-9]/g, '') : null;
  };

  VALID_ID_CHARS = ['A', 'C', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q',  
    'R', 'W', 'X', 'Y', '4', '9'];

  WARN = function(msg){
    console.warn('[' + LIBNAME + '] ' + msg);
  };

  /** 
   * @class
   * Telegram
   * 
   * @param {object} params - telegram parameters
   * @param {string} [params.body] - body of telegram message
   * @param {string} [params.design] - telegram design
   * @params {string} [params.plaintext] - telegram plaintext; if given,
   *   telegram will initialize all properties except for `timeIn` by
   *   parsing the plaintext
   * @params {object} [params.timeIn] - Date received
   * @params {object} [params.timeOut] - Date sent
   * @params {object | string} [params.ustty] - telegram plaintext
   *   encoded using the USTTY character table; if given, the telegram
   *   will initialize all properties except for `timeIn` by parsing
   *   the USTTY plaintext
   * @params {string} [params.usttyFormat] - format of USTTY input
   * 
   * @prop {string} body - body of the telegram (non-metadata message)
   * @prop {string} design - telegram design 
   * @prop {string} id - unique telegram identifier
   * @prop {object} timeIn - Date received
   * @prop {object} timeOut - Date sent
   */
  function Telegram(params){
    params = params || {};

    // Add defaults to params
    for (var key in DEFAULTS){
      if (typeof params[key] === 'undefined'){
        params[key] = DEFAULTS[key];
      }
    }

    // Parse USTTY data (if given) for params
    if (params.ustty){
      this._parseUSTTY(params.ustty, params.usttyFormat);
    }
    // Parse plaintext data (if given) for params
    else if (params.plaintext){
      this._parsePlaintext(params.plaintext);
    }
    // Initialize attributes from params
    else {
      // Initialize `id`
      this.id = params.id || GENERATE_ID();

      // Initialize `timeOut`
      this.timeOut = params.timeOut || new Date();

      // Initialize `design`
      this.design = params.design;

      // Initialize body
      this.body = params.body;
    }

    // Initialize `timeIn`
    this.timeIn = params.timeIn || null;
  }

  /** 
   * Get telegram plaintext. If this were a real telegram, this 
   *   plaintext would be converted to USTTY and sent over a 
   *   telegraph wire.
   * @function
   * @param {object} [params] - parameters
   * @param {string[]} [params.exclude] - attributes not to include
   *   in the plaintext 
   * @return {string} - telegram plaintext
   */
  Telegram.prototype.getPlaintext = function(params){
    var exclude;
    var sections;
    var s1;
    var s2;

    s1 = [];
    s2 = [];
    sections = [];
    exclude = (params && params.exclude) || [];

    // First line
    if (this.id){
      s1.push(FORMAT_ID(this.id));
    }
    if (this.timeOut){
      s1.push(FORMAT_DATE(this.timeOut));
    }
    if (s1.length > 0){
      sections.push(s1.join(' '));
    }

    // Body and design
    if (this.body){
      s2.push(this.body);
    }
    if (this.design && !(exclude.includes('design'))){
      s2.push(FORMAT_DESIGN(this.design));
    }
    if (s2.length > 0){
      sections.push(s2.join(' '));
    }

    return sections.join('=');
  };

  /**
   * Get USTTY representation of this telegram's plaintext. If this
   *   were a real telegram, this USTTY data would be sent as a series
   *   of marking and spacing impulses over a telegraph wire.
   * @function
   * @param {string} format - format for USTTY data. see Corvina docs
   *   for more information. 
   * @returns {object | string} - USTTY data in format specified by 
   *   `format`. By default, this will be an ArrayBuffer whose bits
   *   correspond to the spacing and marking impulses of a telegraph
   *   transmission.
   */
  Telegram.prototype.getUSTTY = function(format){
    format = format || 'data';
    return (new TeletypeModel14({format: format})
      .encode(this.getPlaintext().replace(/=/g, '\n')));
  };  

  /**
   * Is this telegram parsable? i.e. would a new Telegram object 
   *   initialized with this telegram's USTTY representation have
   *   the same properties as this telegram? 
   * @function
   * @return {bool}
   */
  Telegram.prototype.isParsable = function(){
    var dup;
    var ustty;
    var flag;

    if (!this.isUSTTYCompliant()){ 
      WARN('Telegram is not USTTY-compliant' + 
        ' and is therefore not parsable either');
      return false; 
    }

    flag = true;
    ustty = this.getUSTTY();
    dup = new Telegram({ustty: ustty});

    for (var key in this){
      var t;

      if (key === 'timeOut' || key === 'timeIn'){
        t = typeof dup[key] === typeof this[key];
      }
      else {
        t = dup[key] === this[key];
      }
      if (!t){
        WARN('`' + key + '` property is not parsable: expected ' + 
          '`' + this[key] + '` but got ' + 
          '`' + dup[key] + '`');
        flag = false;
      }
    }
    return flag;
  };

  /**
   * Is this telegram compatible with USTTY? In other words, does every
   *  character in this telegram's plaintext representation exist on 
   *  the standard USTTY character table?
   * @function
   * @return {bool}
   */
  Telegram.prototype.isUSTTYCompliant = function(){
    var flags;
    var rex;

    rex = new RegExp(INVALID_CHARS_REGEX_STRING, 'g');
    flags = this.getPlaintext().match(rex) || [];
    for (var i = 0; i < flags.length; i++){
      var c = flags[i];
      WARN('Invalid character: \'' + c + '\' is not USTTY-compliant');
    }
    return (flags.length === 0);
  };

  /**
   * Parse Telegram attributes from a given telegram plaintext string
   * @function
   * @param {string} plaintext - telegram plaintext
   */
  Telegram.prototype._parsePlaintext = function(plaintext){
    this.timeOut = PARSE_DATE(plaintext);
    this.id = PARSE_ID(plaintext);
    this.design = PARSE_DESIGN(plaintext);
    this.body = PARSE_BODY(plaintext);
  };

  /**
   * Parse Telegram attributes from a given telegram plaintext string
   *   encoded using the USTTY character table
   * @function
   * @param {object | string} ustty - USTTY-encoded telegram plaintext.
   *   By default, this should be an ArrayBuffer whose bits correspond
   *   to spacing and marking impulses.
   * @param {format} string - format of USTTY input
   */
  Telegram.prototype._parseUSTTY = function(ustty, format){
    var plaintext;
    
    format = format || 'data';
    plaintext = (new TeletypeModel14({format: format})
      .decode(ustty));

    this._parsePlaintext(plaintext);
  };

  /**
   * Length of telegram identifiers
   * @constant
   */
  Telegram.idLength = 10;

  // Export module
  window.Telegram = Telegram;

})(document, window);