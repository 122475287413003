'use strict';

(function(undefined){
  var apiEndpoint;
  var defaults;
  var telegramFileExt;
  var libName;
  var logResponse;
  var resourcePath;
  var versions;

  libName = 'Essex Client';

  apiEndpoint = 
    'https://472aoaced9.execute-api.us-west-2.amazonaws.com';

  telegramFileExt = '.ustty';

  resourcePath = 'telegrams';

  versions = {
    staging: 'staging', // staging (development) version
    v1: 'v1'            // live v1 version
  };

  /**
   * @function
   * Prints the given error and / or result to the console.
   * @param {string | null} error - error msg (if any) returned by API 
   * @param {object} result - result of the API call parsed by JSON
   */
  logResponse = function(error, result){
    if (error){
      console.warn(libName + ': Put Telegram Error: ' + error);
    } else{
      console.log(libName + ': Put Telegram Result: ' + result);
    }
  };

  /**
   * @namespace
   * Default configurations
   * @prop {function} onPut - callback to run on `putTelegram` request
   * @prop {string} version - API version
   **/
  defaults = {
    onGet: logResponse,
    onPut: logResponse,
    version: versions.staging
  };

  /**
   * @class
   * Client for the Essex API (https://bitbucket.org/ivyinsights/essex)
   * @param {object} [options] - configuration options
   * @param {function} [option.onGet] - callback to run after sending
   *   a GET telegram request. Callback takes two arguments. `error` 
   *   will be `null` if the request was successful, otherwise it will
   *   be an error message. `result` will be an ArrayBuffer of telegram
   *   impulse data if the request was successful.
   * @param {function} [options.onPut] - callback to run after sending
   *   a PUT telegram request. Callback takes two arguments. `error` 
   *   will be `null` if the request was successful, otherwise it 
   *   will be a {string} error message. `result` will be the 
   *   JSON-parsed result returned by the Essex API.
   * @param {string} [options.version] - Essex API version
   * @prop {object} xhr - XMLHttpRequest object
   **/
  function EssexClient(options){
    options = options || {};

    this._config = (function(){
      var c = defaults;
      for (var key in options){
        c[key] = options[key];
      }
      return c;
    })();
  }

  /**
   * @function
   * GET telegram data with a given telegram identifier.
   * @params {string} id - telegram identifier
   **/
  EssexClient.prototype.getTelegram = function(id){
    var url;
    var self;

    url = [apiEndpoint, this._config.version, resourcePath, 
      id].join('/');
    self = this;
    this.xhr = new XMLHttpRequest();

    this.xhr.open('GET', url);
    this.xhr.responseType = 'arraybuffer';

    this.xhr.onreadystatechange = function(){
      if(self.xhr.readyState === XMLHttpRequest.DONE) {
        if (self.xhr.status !== 200){
          self._config.onGet('Could not find telegram with id: ' + id);
        }
        else {
          self._config.onGet(null, self.xhr.response); 
        }
      }
    };
    this.xhr.send();
  };

  /**
   * @function
   * Send a PUT telegram request to the Essex API.
   * @param {object} params - request params
   * @param {string} params.id - telegram ID. See Essex API docs
   *   for validation requirements.
   * @param {string} params.signal - base64-formatted, USTTY-encoded  
   *   telegram signal data. 
   **/
  EssexClient.prototype.putTelegram = function(params){
    var url;
    var self; 

    self = this;
    url = [apiEndpoint, this._config.version, resourcePath].join('/');
    this.xhr = new XMLHttpRequest();
    this.xhr.open('PUT', url);
    this.xhr.setRequestHeader('Content-Type', 'application/json');
    
    this.xhr.onreadystatechange = function(){
      var result;
      var error;

      result = null;
      error = null;
      if(self.xhr.readyState === XMLHttpRequest.DONE) {
        result = JSON.parse(self.xhr.responseText);
        if (self.xhr.status !== 200){
          error = result.errorMessage;
        }
        self._config.onPut(error, result);
      }
    };

    this.xhr.send(JSON.stringify(params));
  };

  // Export module
  window.EssexClient = EssexClient;

})();