'use strict';

(function(undefined){
  var BACK_BUTTON_ID;
  var BASENAME;
  var CATALOG_ELEMENT_SEL;
  var CATALOG_ITEMS;
  var DECODE_USTTY;
  var GET_TELEGRAM_ID;
  var LIBNAME;
  var PAYMENT_FAILED_MESSAGE;
  var SEND_ANALYTICS_EVENT;
  var STANDBY_MESSAGE;
  var START_MESSAGE;

  CATALOG_ITEMS = [
    {
      amount: 0,
      design: '180206',
      title: 'Classic Telegram Design'
    },
    {
      amount: 0,
      design: '180227',
      title: 'Soviet Express Telegram Design'
    },
    {
      amount: 50,
      design: '171222',
      title: 'U.K. Royal Mail Telegram Design'
    },
    {
      amount: 50,
      design: '171223',
      title: 'Imperial German Telegram Design'
    },
    {
      amount: 50,
      design: '180226',
      title: 'Irish Telegram Design'
    }
  ];

  BACK_BUTTON_ID = 'back-ribbon-button';
  CATALOG_ELEMENT_SEL = '#catalog';
  LIBNAME = 'Catalog Controller';
  BASENAME = LIBNAME.toLowerCase().replace(' ', '-');
  PAYMENT_FAILED_MESSAGE = 'Oops, something went wrong.<br>' + 
    'Please try again!'; 
  START_MESSAGE = 'Last step... <br><strong>Pick a telegram design' +
    '!</strong>';
  STANDBY_MESSAGE = 'Securely processing your payment.<br>' + 
    '<strong>Please stand by...</strong>';

  /**
   * @function
   * Returns the ID of the current telegram, if any
   * @return {string} - unique telegram identifier
   **/
  GET_TELEGRAM_ID = function(){
    return window.location.hash.substr(2, Telegram.idLength);
  };

  /** 
   * @function
   * Decode an ArrayBuffer of USTTY data into plaintext
   * @return {string} - plaintext string 
   **/
  DECODE_USTTY = function(ustty){
    return (new TeletypeModel14({format: 'data'}).decode(ustty));
  };

  /**
   * @function
   * Send event to Google Analytics
   */
  SEND_ANALYTICS_EVENT = function(label, value){
    ga('send', 'event', 'catalog', label, value);
  };

  /**
   * @class
   * The CatalogController is responsible for managing the UI of the 
   *   catalog page. 
   * @prop {object} backButton - RibbonButtonView back button
   * @prop {object} catalogElement - HTML element for catalog (container
   *   for viewable telegram designs)
   * @prop {object} essexClient - EssexClient for fetching telegram data
   * @prop {object} hud - H.U.D. (Puffin) for displaying messages,
   *   warnings, and prompts to the operator
   * @prop {object} intrepidClient - EssexClient for fetching and 
   *   updating telegram data
   * @prop {string} selectedDesign - currently-selected telegram design
   * @prop {string} telegramId - telegram identifier of the operator's
   *   current telegram
   * @prop {object} telegram - current Telegram object 
   * @prop {string} telegramPlaintext - plaintext of operator's current
   *   telegram
   **/
  function CatalogController(){
    var self;
    var isDevEnv;

    self = this;
    isDevEnv = app.environment === 'development';

    this.intrepidClient = new IntrepidClient({
      callback: function(error, data){
        data = data;
        self._handleCharge(error);
      },
      mode: isDevEnv ? 'test' : 'live'
    });
    this.essexClient = new EssexClient({
      onGet: function(e, d){ self._onGetTelegram(e, d); },
      onPut: function(e, d){ self._onPutTelegram(e, d); },
      version: isDevEnv ? 'staging' : 'v1'
    });
  }
  
  /**
   * @function
   * Code to run once the compose page is displayed to the operator.
   **/
  CatalogController.prototype.viewDidAppear = function(){
  };

  /**
   * @function
   * Code to run once the page is no longer displayed to the 
   *   operator. 
   **/
  CatalogController.prototype.viewDidDisappear = function(){

  };

  /**
   * @function
   * Code to run once the appropriate DOM elements has loaded. 
   *   Initializes all views.
   **/
  CatalogController.prototype.viewDidLoad = function(){
    // Get current telegram ID
    this.telegramId = GET_TELEGRAM_ID();

    // Initialize subviews
    this.backButton = new RibbonButtonView(
      document.getElementById(BACK_BUTTON_ID), {
        onClick: function(){
          window.history.back();
        },
        position: 'left',
        text: 'Back'
      }
    );
    this.hud = new Puffin();
    this.catalogElement = document.querySelector(CATALOG_ELEMENT_SEL);

    // Render subviews
    this.hud.says(START_MESSAGE);
    this.backButton.render();

    // Get USTTY data for current telegram via Essex API client
    this.essexClient.getTelegram(this.telegramId);
  };

  CatalogController.prototype._submitChoice = function(){
    this.telegram.design = this.selectedDesign;
    this.essexClient.putTelegram({
      id: this.telegram.id, 
      signal: this.telegram.getUSTTY('base64')
    });
  };

  CatalogController.prototype._handleCharge = function(error){
    if (error){
      SEND_ANALYTICS_EVENT('stripe-payment', 'error');
      this.hud.says(START_MESSAGE);
      this.hud.warns(PAYMENT_FAILED_MESSAGE);
    } 
    else {
      SEND_ANALYTICS_EVENT('stripe-payment', 'complete');
      this._submitChoice();
    }
  };

  CatalogController.prototype._handleChoice = function(index){
    var item;
    var self;

    self = this;
    item = CATALOG_ITEMS[index];

    SEND_ANALYTICS_EVENT('choose-' + item.design);
    this.selectedDesign = item.design;

    // handle free telegrams
    if (item.amount === 0){
      this._submitChoice();
    }
    // handle paid telegrams
    else{
      SEND_ANALYTICS_EVENT('stripe-payment', 'start');
      new PaymentPrompt({
        amount: item.amount,
        description: item.title,
        mode: app.environment === 'development' ? 'test' : 'live',
        tokenHandler: function(token){
          SEND_ANALYTICS_EVENT('stripe-payment', 'token');
          self.intrepidClient.createCharge(token.id);
          self.hud.says(STANDBY_MESSAGE);
        }
      }).show();
    }
  };

  /**
   * @function
   * Handle successfully fetching Telegram data
   **/
  CatalogController.prototype._onGetTelegram = function(error, data){

    if (error){
      console.warn(error);
    } else{
      this.telegram = new Telegram({ustty: data});
      this.telegramPlaintext = this.telegram.getPlaintext({
        exclude: 'design'
      });
      this._renderTelegrams();
    }
  };

  CatalogController.prototype._onPutTelegram = function(error, data){
    data = data;
    
    if (error){
      console.warn(error);
    } else {
      window.location.href = '/done/#/' + this.telegram.id;
    }
  };

  /**
   * @function
   * Renders the catalog's telegram views
   **/
  CatalogController.prototype._renderTelegrams = function(){
    var catItem;
    var el;
    var elId;
    var handler;
    var self;

    self = this;

    handler = function(i){
      self._handleChoice(i);
    };

    for (var i = 0; i < CATALOG_ITEMS.length; i++){
      elId = 'telegram-' + i;
      catItem = CATALOG_ITEMS[i];

      el = document.createElement('DIV');
      el.id = elId;
      this.catalogElement.appendChild(el);

      new CatalogItemView(el, {
        amount: catItem.amount,
        index: i,
        onClick: handler,
        telegramDesign: catItem.design,
        telegramText: this.telegramPlaintext,
        telegramWidth: Math.min(screen.width, 700)
      });
    }
  };

  // Export module
  window.CatalogController = CatalogController;

})();